import customizeColors from '@upstream/cobot_assets/src/customize-colors.js';

const colors = document.body.dataset;
if (colors.primary) {
  customizeColors({
    colors: {
      text: colors.text,
      'primary-70': colors.primary,
      'highlight-70': colors.highlight,
      background: colors.background,
      'admin-brand': colors.adminBrand,
    },
  });
} else {
  customizeColors();
}
